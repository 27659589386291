.errorbody {
  height: calc(100vh - 175px);
  width: 100%;
  align-content: center;
  p {
    margin: 0;
  }
  .errorPage__container {
    width: 60%;
    margin: 0 auto;
    text-align: center;
  }
  .logOut-btn {
    background-color: #096dd9;
    color: white;
    border: none;
    height: 30px;
    width: 82px;
    border-radius: 3px;
    margin: 20px 0px;
    cursor: pointer;
  }
}
.errorHeader {
  padding-left: 30px;
  background-color: #f6fbff;
  height: 80px;
  align-content: center;
}
